import './style.css'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/noto-sans-kr"; 
import artistCsv from './artistsSample.csv'
import artistWorksCsv from './artistworks.csv'
import archiveList from './archiveList.csv'
const artistWorksOffset = 20;
var $ = require( "jquery" )

import menubarHomeOff1 from './img/icon-home-off-1.png';
import menubarHomeOff2 from './img/icon-home-off-2.png';
import menubarTpsOff1 from './img/icon-tps-off-1.png';
import menubarTpsOff2 from './img/icon-tps-off-2.png';
import menubarVbOff from './img/icon-vb-off.png';
import menubarHomeOn from './img/icon-home-on.png';
import menubarTpsOn from './img/icon-tps-on.png';
import menubarVbOn from './img/icon-vb-on.png';
import menubarVb2021Off1 from './img/icon-vb-2021-off-1.png';
import menubarVb2021Off2 from './img/icon-vb-2021-off-2.png';
import { Path } from 'three'

// console.log(window.location.search)

const saveImageTraffic = false;

const numberOfArtists = artistCsv.length;
var artistImages = Array(numberOfArtists);
// console.log('Number of Artists: '+ numberOfArtists)

const imageRegex = new RegExp('\.(jpe?g|png|gif|svg)$');

const searchRegExpStart = '\(hanja\)';
const searchRegExpEnd = '\(hanja_end\)';
const replaceWithStart = '<span style="font-family: sans-serif">';
const replaceWithEnd = '</span>';

for(let artistNum = 0; artistNum < numberOfArtists; artistNum++)
{
    artistImages[artistNum] = [];
    if(artistCsv[artistNum].imageListStr == undefined)
    {
        continue;
    }
    let artistImageListArr = artistCsv[artistNum].imageListStr.split(",");
    let numberOfWorks = artistImageListArr.length;
    artistImages[artistNum] = Array(numberOfWorks)

    for(let workNum = 0; workNum < numberOfWorks; workNum++)
    {
        if(imageRegex.test(artistImageListArr[workNum]))
        {
            if(!saveImageTraffic)
            {
                artistImages[artistNum][workNum] = require(`./img/artists/${artistNum}/${artistImageListArr[workNum]}`);
            }
            else
            {
                // console.log('Save Image Traffic: '+artistImageListArr[workNum]);
                artistImages[artistNum][workNum] = null;
            }
        }
        else
        {
            console.log('Invalid image format: '+artistImageListArr[workNum]);
            artistImages[artistNum][workNum] = null;
        }
    }
}

const numberOfArchives = archiveList.length;
if(numberOfArchives)
{
    document.getElementById('vb-archive-preparing').style.display='none';
}

for(let archiveNum = 0; archiveNum < numberOfArchives; archiveNum++)
{
    let archiveElem = document.createElement('a');
    archiveElem.classList.add('vb-archive-content-item');
    archiveElem.setAttribute('href',archiveList[archiveNum].link);
    archiveElem.setAttribute('target',"_blank");

    // IMAGE
    let imageBoxElem = document.createElement('div');
    imageBoxElem.classList.add('vb-archive-content-image-box');
    let imageElem = document.createElement('img');
    imageElem.classList.add('vb-archive-content-image');
    // setMetaImage(imageElem, archiveList[archiveNum].link, 2<=archiveNum&&archiveNum<=4 ? archiveDefault : characterCube );
    imageElem.setAttribute('src', require(`./img/archive/${archiveList[archiveNum].image}`));
    imageBoxElem.appendChild(imageElem);
    archiveElem.appendChild(imageBoxElem);

    // TITLE
    let titleElem = document.createElement('div');
    titleElem.classList.add('vb-archive-content-item-title');
    titleElem.innerHTML = convertToHtmlString(archiveList[archiveNum].title, false);
    archiveElem.appendChild(titleElem);

    // DATE
    let dateElem = document.createElement('div');
    dateElem.classList.add('vb-archive-content-item-date');
    dateElem.innerHTML = archiveList[archiveNum].date;
    archiveElem.appendChild(dateElem);

    // PLATFORM
    let platformElem = document.createElement('div');
    platformElem.classList.add('vb-archive-content-item-platform');
    platformElem.innerHTML = archiveList[archiveNum].platform;
    archiveElem.appendChild(platformElem);

    archiveElem.archiveNum = archiveNum;

    document.getElementById('vb-archive-content-container').appendChild(archiveElem)
}

const webTitle = 'VIDEO BITES';

let artistListLoaded = false;
let posterShown = false;

const isDebug = false;

const ArtistShowState = {
    NONE : -1,
    LIST : 0,
    ARTIST_INFO : 1,
    ARTIST_WORK : 2,
    ARTIST_TIMETABLE : 3
}

const TimetableState = {
    NONE_TOGGLE: 0,
    BOOTH_1: 1,
    BOOTH_2: 2
}

var currentTimetableState = TimetableState.NONE_TOGGLE;

var currentWebLocation = {
    menu : 0,
    page : 0,
    artistState : ArtistShowState.NONE,
    artist : -1,
    work : -1
}


if(isDebug)
{
    // setMenubar(1);
    setMenubar(2);
    setVbMenubar(1);

    openTimetable();
}
else
{
    let menuNumber = get('menu');
    let pageNumber = get('page');
    let artistNumber = get('artist');
    let workNumber = get('work');

    
    if(menuNumber!==undefined && 0<=menuNumber && menuNumber<=2)
    {
        console.log('Menu : ' + menuNumber);
        setMenubar(menuNumber);
        if(menuNumber == 2 && pageNumber!==undefined && 0<=pageNumber && pageNumber<=4)
        {
            console.log('Page : ' + pageNumber);
            setVbMenubar(pageNumber);
            if(artistNumber!==undefined && 0<= artistNumber && artistNumber < numberOfArtists)
            {
                posterShown = true;
                enableScroll();
                document.getElementById('poster-window').style.display = 'none';
                console.log('Artist : ' + artistNumber);
                openArtistInfo(artistNumber, true, true);

                if(workNumber!==undefined && 0<=workNumber && workNumber < artistImages[artistNumber].length)
                {
                    console.log('Work : ' + workNumber);    
                    openArtistInfo_Work(workNumber);
                }
            }
        }
    }
    else
    {
        // console.log('Home')
        setMenubar(0);
    }
    
}

pushCurrentState()


window.addEventListener('resize', () =>
{
    resizeVideobitesContent();
    resetTimetableState();
})

function resizeVideobitesContent()
{
    if(window.innerWidth >= window.innerHeight)
    {
        // Landscape
        document.getElementById('poster-window').classList.add('poster-horizontal');
        document.getElementById('poster-window').classList.remove('poster-vertical');
    }
    else
    {
        // Portrait
        document.getElementById('poster-window').classList.remove('poster-horizontal');
        document.getElementById('poster-window').classList.add('poster-vertical');
    }
}

function addVideobitesEvents()
{
    document.getElementById('poster-close').addEventListener('click', ()=>{
        enableScroll();
        document.getElementById('poster-window').style.display = 'none';
    })

    document.getElementById('menu-bar-home').addEventListener('click', openHome);
    document.getElementById('menu-bar-tps').addEventListener('click', openTps);
    document.getElementById('menu-bar-vb').addEventListener('click', openVb);

    document.getElementById('vb-menu-bar-about').addEventListener('click', openVbAbout);
    document.getElementById('vb-menu-bar-artist').addEventListener('click', openVbArtist);
    // document.getElementById('vb-menu-bar-special').addEventListener('click', openVbSpecial);
    document.getElementById('vb-menu-bar-program').addEventListener('click', openVbProgram);
    document.getElementById('vb-menu-bar-archive').addEventListener('click', openVbArchive);
    // document.getElementById('vb-artist-content-works-timetable').addEventListener('click', ()=>{
    //     openTimetable();
    //     pushCurrentState();
    // });
    document.getElementById('vb-artist-content-timetable-back').addEventListener('click', ()=>{
        // openArtistInfo(currentWebLocation.artist, false);
        // pushCurrentState();
        window.history.back()
    });

    document.getElementById('timetable-toggle-1').addEventListener('click', ()=>{
        toggleTimetable(1)
    })
    document.getElementById('timetable-toggle-2').addEventListener('click', ()=>{
        toggleTimetable(2)
    })

    document.getElementById('vb-artist-content-info-back').addEventListener('click', ()=>{
        // if(currentWebLocation.artistState == ArtistShowState.ARTIST_INFO)
        // {
        //     openArtistList();
        //     pushCurrentState();
        // }
        // else if(currentWebLocation.artistState == ArtistShowState.ARTIST_WORK)
        // {
        //     openArtistInfo(currentWebLocation.artist, false);
        //     pushCurrentState();
        // }

        window.history.back()
    });
}
resizeVideobitesContent();
addVideobitesEvents();


function openHome()
{
    setMenubar(0);
    pushCurrentState();
}

function openTps()
{
    setMenubar(1);
    pushCurrentState();
}

function openVb()
{
    setMenubar(2);
    pushCurrentState();
}

function setMenubar(index, scrollToTop = true)
{
    if(index<2)
    {
        document.getElementById('menu-bar-home').setAttribute('src', menubarHomeOff1);
        document.getElementById('menu-bar-tps').setAttribute('src', menubarTpsOff1);
        document.getElementById('menu-bar-vb-2021').setAttribute('src', menubarVb2021Off1);
    }
    else
    {
        document.getElementById('menu-bar-home').setAttribute('src', menubarHomeOff2);
        document.getElementById('menu-bar-tps').setAttribute('src', menubarTpsOff2);
        document.getElementById('menu-bar-vb-2021').setAttribute('src', menubarVb2021Off2);
    }

    document.getElementById('menu-bar-vb').setAttribute('src', menubarVbOff);
    hideAll();

    if(scrollToTop == true)
    {
        window.scrollTo(0, 0);
    }

    switch(index)
    {
        case 0:
        case '0':
            document.getElementById('menu-bar-home').setAttribute('src', menubarHomeOn);
            document.getElementById('body-window').style.backgroundColor = '#ebebed';
            document.getElementById('home-window').style.display = 'block';
            currentWebLocation.menu = 0;
            break;
        case 1:
        case '1':
            document.getElementById('menu-bar-tps').setAttribute('src', menubarTpsOn);
            document.getElementById('body-window').style.backgroundColor = '#9affd8';
            document.getElementById('tps-window').style.display = 'block';
            currentWebLocation.menu = 1;
            break;
        case 2:
        case '2':
            document.getElementById('menu-bar-vb').setAttribute('src', menubarVbOn);
            document.getElementById('body-window').style.backgroundColor = 'white';
            document.getElementById('vb-window').style.display = 'flex';
            currentWebLocation.menu = 2;
            setVbMenubar(0, scrollToTop);
            if(!posterShown)
            {
                document.getElementById('poster-window').style.display='flex';
                posterShown = true;
                disableScroll();
            }
            break;
        default:
            break;
    }
}

function openVbAbout(){
    setVbMenubar(0);
    pushCurrentState();
}

function openVbArtist(){
    setVbMenubar(1);
    pushCurrentState();
}

function openVbSpecial(){
    setVbMenubar(2);
    pushCurrentState();
}

function openVbProgram(){
    setVbMenubar(3);
    pushCurrentState();
}

function openVbArchive(){
    setVbMenubar(4);
    pushCurrentState();
}

function setVbMenubar(index, scrollToTop = true)
{
    document.getElementById('vb-menu-bar-about').classList.remove('vb-menu-bar-item-selected');
    document.getElementById('vb-menu-bar-artist').classList.remove('vb-menu-bar-item-selected');
    // document.getElementById('vb-menu-bar-special').classList.remove('vb-menu-bar-item-selected');
    document.getElementById('vb-menu-bar-program').classList.remove('vb-menu-bar-item-selected');
    document.getElementById('vb-menu-bar-archive').classList.remove('vb-menu-bar-item-selected');
    hideVbAll();

    if(scrollToTop == true)
    {
        window.scrollTo(0, 0);
    }

    switch(index)
    {
        case 0:
        case '0':
            document.getElementById('vb-menu-bar-about').classList.add('vb-menu-bar-item-selected');
            document.getElementById('vb-about-content').style.display = 'block';
            currentWebLocation.page = 0;
            break;
        case 1:
        case '1':
            document.getElementById('vb-menu-bar-artist').classList.add('vb-menu-bar-item-selected');
            document.getElementById('vb-artist-content').style.display = 'block';
            currentWebLocation.page = 1;
            openArtistList(scrollToTop);
            break;
        case 2:
        case '2':
            // document.getElementById('vb-menu-bar-special').classList.add('vb-menu-bar-item-selected');
            document.getElementById('vb-artist-content').style.display = 'block';
            document.getElementById('vb-special-content-container').style.display = 'flex';
            currentWebLocation.page = 2;
            break;
        case 3:
        case '3':
            document.getElementById('vb-menu-bar-program').classList.add('vb-menu-bar-item-selected');
            document.getElementById('vb-program-content').style.display = 'flex';
            currentWebLocation.page = 3;
            break;
        case 4:
        case '4':
            document.getElementById('vb-menu-bar-archive').classList.add('vb-menu-bar-item-selected');
            document.getElementById('vb-artist-content').style.display = 'block';
            document.getElementById('vb-archive-content-container').style.display = 'flex';
            currentWebLocation.page = 4;
            break;
        default:
            break;
    }
}

function openArtistList(scrollToTop = true)
{
    stopVideo();
    if(scrollToTop)
    {
        window.scrollTo(0, 0);
    }

    if(!artistListLoaded)
    {
        for(let artistNum = 0; artistNum<numberOfArtists; artistNum++)
        {
            let imageBoxElem = document.createElement('div');
            imageBoxElem.classList.add('vb-artist-content-image-box');
            let imageElem = document.createElement('img');
            imageElem.classList.add('vb-artist-content-image');
            if(artistImages[artistNum].length>0)
            imageElem.setAttribute('src',artistImages[artistNum][0]);
            imageBoxElem.appendChild(imageElem);

            imageBoxElem.artistNum = artistNum;
            imageBoxElem.addEventListener('click',(evt)=>{
                openArtistInfo(evt.currentTarget.artistNum, true, true);
                pushCurrentState();
            });

            document.getElementById('vb-artist-content-image-container').appendChild(imageBoxElem)
        }
        artistListLoaded = true;
    }


    currentWebLocation.artistState = ArtistShowState.LIST;
    document.getElementById('vb-artist-content-image-container').style.display = 'flex';
    document.getElementById('vb-artist-content-info-container').style.display = 'none';
    document.getElementById('vb-artist-content-timetable-container').style.display = 'none';
    currentWebLocation.artist = -1;
}

function openArtistInfo(artistNum, reload, scrollToTop)
{
    currentWebLocation.artist = artistNum;
    currentWebLocation.artistState = ArtistShowState.ARTIST_INFO;
    console.log('Open Artist Info : '+artistNum);


    // Set Artist Info / Text
    if(reload)
    {
        if(scrollToTop == true)
        {
            window.scrollTo(0, 0);
        }
        document.getElementById('vb-artist-content-info-title').innerHTML = artistCsv[artistNum].artistNameKor;
        document.getElementById('vb-artist-content-info-subtitle').innerHTML = artistCsv[artistNum].artistNameEng;
        
        if(artistCsv[artistNum].birthYear)
        {
            document.getElementById('vb-artist-content-info-birth').innerHTML = artistCsv[artistNum].birthYear + '년생';
            document.getElementById('vb-artist-content-info-birth').style.display = 'block';
        }
        else
        {
            document.getElementById('vb-artist-content-info-birth').innerHTML = '';
            document.getElementById('vb-artist-content-info-birth').style.display = 'none';
        }

        document.getElementById('vb-artist-content-info-about').innerHTML = convertToHtmlString(artistCsv[artistNum].artistInfo);
        document.getElementById('vb-artist-content-info-history').innerHTML = convertToHtmlString(artistCsv[artistNum].artistHistory);
        document.getElementById('vb-artist-content-works-text').innerHTML = convertToHtmlString(artistCsv[artistNum].artistWorkInfo);

        if(artistCsv[artistNum].artistHomepage)
        {
            document.getElementById('vb-artist-content-info-homepage').innerHTML = artistCsv[artistNum].artistHomepage;
            document.getElementById('vb-artist-content-info-homepage').setAttribute('href', artistCsv[artistNum].artistHomepage)
            document.getElementById('vb-artist-content-info-homepage').style.display = 'block';
        }
        else
        {
            document.getElementById('vb-artist-content-info-homepage').innerHTML = '';
            document.getElementById('vb-artist-content-info-homepage').setAttribute('href', '')
            document.getElementById('vb-artist-content-info-homepage').style.display = 'none';
        }
        if(artistCsv[artistNum].artistHomepage2)
        {
            document.getElementById('vb-artist-content-info-homepage2').innerHTML = artistCsv[artistNum].artistHomepage2;
            document.getElementById('vb-artist-content-info-homepage2').setAttribute('href', artistCsv[artistNum].artistHomepage2)
            document.getElementById('vb-artist-content-info-homepage2').style.display = 'block';
        }
        else
        {
            document.getElementById('vb-artist-content-info-homepage2').innerHTML = '';
            document.getElementById('vb-artist-content-info-homepage2').setAttribute('href', '')
            document.getElementById('vb-artist-content-info-homepage2').style.display = 'none';
        }
        // if(artistCsv[artistNum].boothNumber)
        // {
        //     document.getElementById('vb-artist-content-works-main-reservation').style.display = 'block'
        //     document.getElementById('vb-artist-content-works-booth').innerHTML = 'BOOTH '+artistCsv[artistNum].boothNumber+' - '+artistCsv[artistNum].boothTimePeriod+'회차';
        // }
        // else
        // {
        //     document.getElementById('vb-artist-content-works-main-reservation').style.display = 'none'
        //     document.getElementById('vb-artist-content-works-booth').innerHTML = ''
        // }
        
        document.getElementById('vb-artist-content-works-main-caption').innerHTML = artistCsv[artistNum].caption;
        
        let artistLink = artistCsv[artistNum].youtubeLink;
        artistLink.substring(artistLink.lastIndexOf('/')+1)
        // document.getElementById('ytplayer').setAttribute('src', 'https://www.youtube.com/embed/'+artistLink.substring(artistLink.lastIndexOf('/')+1)+'?enablejsapi=1')
        let ytLink = 'https://www.youtube.com/embed/'+artistLink.substring(artistLink.lastIndexOf('/')+1)+'?enablejsapi=1';
        changeIframeSrc(document.getElementById('ytplayer'), ytLink);

        const imageList = document.getElementById('vb-artist-content-works-image-list')
        while (imageList.lastElementChild) {
            imageList.removeChild(imageList.lastElementChild);
        }
    
        for(let workNum = 0; workNum < artistImages[artistNum].length; workNum++)
        {
            let imageBoxElem = document.createElement('div');
            imageBoxElem.classList.add('vb-artist-content-works-image-item');
            let imageElem = document.createElement('img');
            imageElem.setAttribute('src',artistImages[artistNum][workNum]);
            imageBoxElem.appendChild(imageElem);
    
            imageBoxElem.workNum = workNum;
            imageBoxElem.addEventListener('click',(evt)=>{
                openArtistInfo_Work(evt.currentTarget.workNum);
                pushCurrentState();
            });
    
            imageList.appendChild(imageBoxElem)
    
        }
    }


    document.getElementById('vb-artist-content-image-container').style.display = 'none';
    document.getElementById('vb-artist-content-info-container').style.display = 'flex';
    document.getElementById('vb-artist-content-info-box').style.display = 'block';
    document.getElementById('vb-artist-content-works-box').style.display = 'flex';
    document.getElementById('vb-artist-content-works-specific-box').style.display = 'none';
    document.getElementById('vb-artist-content-timetable-container').style.display = 'none';
}

function openArtistInfo_Work(workNum)
{
    pauseVideo();

    workNum = parseInt(workNum);
    
    if(currentWebLocation.artist < 0 || numberOfArtists <= currentWebLocation.artist)
    {
        console.log('Invalid Artist');
        return;
    }

    if(artistImages[currentWebLocation.artist].length <= workNum)
    {
        console.log('Invalid Work Number');
        return;
    }

    let index = (artistWorksOffset * currentWebLocation.artist) + workNum;
    if(!artistWorksCsv[index])
    {
        console.log('Invalid Work Data at '+index + ' (artistNum: '+currentWebLocation.artist+')');
        return;
    }

    // window.scrollTo(0, 0);
    document.getElementById('vb-artist-content-works-specific-box').scrollTop = 0;

    document.getElementById('vb-artist-content-works-specific-image-file').setAttribute('src',artistImages[currentWebLocation.artist][workNum])
    document.getElementById('vb-artist-content-works-specific-caption').innerHTML = convertToHtmlString(artistWorksCsv[index].caption);
    document.getElementById('vb-artist-content-works-specific-price').innerHTML = artistWorksCsv[index].price;
    document.getElementById('vb-artist-content-works-specific-contact-box').style.display = artistWorksCsv[index].isSelling == 1 ? 'flex' : 'none';
    
    currentWebLocation.artistState = ArtistShowState.ARTIST_WORK;
    currentWebLocation.work = workNum;
    console.log('Open Artist : '+currentWebLocation.artist+', Work Info : '+workNum);
    document.getElementById('vb-artist-content-image-container').style.display = 'none';
    document.getElementById('vb-artist-content-info-container').style.display = 'flex';
    document.getElementById('vb-artist-content-info-box').style.display = 'block';
    document.getElementById('vb-artist-content-works-box').style.display = 'none';
    document.getElementById('vb-artist-content-works-specific-box').style.display = 'flex';
    document.getElementById('vb-artist-content-timetable-container').style.display = 'none';
}

function openTimetable(workNum)
{
    pauseVideo();
    window.scrollTo(0, 0);

    if(currentTimetableState != TimetableState.NONE_TOGGLE)
    {
        if(artistCsv[currentWebLocation.artist].boothNumber)
        {
            toggleTimetable(artistCsv[currentWebLocation.artist].boothNumber)
        }
    }

    currentWebLocation.artistState = ArtistShowState.ARTIST_TIMETABLE;

    document.getElementById('vb-artist-content-image-container').style.display = 'none';
    document.getElementById('vb-artist-content-info-container').style.display = 'none';
    document.getElementById('vb-artist-content-timetable-container').style.display = 'block';
}

function hideAll()
{
    document.getElementById('home-window').style.display = 'none';
    document.getElementById('tps-window').style.display = 'none';
    document.getElementById('vb-window').style.display = 'none';

    currentWebLocation.artistState = ArtistShowState.NONE;
    currentWebLocation.artist = -1;

    stopVideo();
}

function hideVbAll()
{
    document.getElementById('vb-about-content').style.display = 'none';
    document.getElementById('vb-artist-content').style.display = 'none';
    // document.getElementById('vb-special-content').style.display = 'none';
    document.getElementById('vb-program-content').style.display = 'none';
    // document.getElementById('vb-archive-content').style.display = 'none';
    
    document.getElementById('vb-artist-content-image-container').style.display = 'none';
    document.getElementById('vb-artist-content-info-container').style.display = 'none';
    document.getElementById('vb-artist-content-timetable-container').style.display = 'none';
    document.getElementById('vb-special-content-container').style.display = 'none';
    document.getElementById('vb-archive-content-container').style.display = 'none';

    currentWebLocation.artistState = ArtistShowState.NONE;
    currentWebLocation.artist = -1;

    stopVideo();
}


function convertToHtmlString(input, checkHanja = true)
{
    if(checkHanja)
    {
        return input.replaceAll('<', '&lt;').replaceAll('>','&gt;').replaceAll('\n','<br>').replace(searchRegExpStart, replaceWithStart).replace(searchRegExpEnd, replaceWithEnd);
    }
    else
    {
        return input.replaceAll('<', '&lt;').replaceAll('>','&gt;').replaceAll('\n','<br>');
    }
}

function playVideo()
{
    document.getElementById('ytplayer').contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
}

function stopVideo()
{
    document.getElementById('ytplayer').contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
}

function pauseVideo()
{
    document.getElementById('ytplayer').contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
}

resetTimetableState();
function resetTimetableState()
{
    const toggleModeWidth = 1400;

    if(window.innerWidth<=toggleModeWidth)
    {
        for(var booth of document.getElementsByClassName('vb-artist-content-timetable-booth'))
        {
            booth.style.diplay = 'none'
            booth.style.width = '98%'
        }
        for(var title of document.getElementsByClassName('vb-artist-content-timetable-booth-title'))
        {
            title.style.display='none';
        }
        for(var title of document.getElementsByClassName('vb-artist-content-timetable-booth-title-toggle'))
        {
            title.style.display='block';
        }

        if(currentTimetableState == TimetableState.BOOTH_2)
        {
            toggleTimetable(2);
        }
        else
        {
            toggleTimetable(1);
        }
    }
    else
    {
        currentTimetableState = TimetableState.NONE_TOGGLE;
        for(var booth of document.getElementsByClassName('vb-artist-content-timetable-booth'))
        {
            booth.style.diplay = 'block'
            booth.style.width = '48%'
        }
        for(var title of document.getElementsByClassName('vb-artist-content-timetable-booth-title'))
        {
            title.style.display='block';
        }
        for(var title of document.getElementsByClassName('vb-artist-content-timetable-booth-title-toggle'))
        {
            title.style.display='none';
        }
        document.getElementById('vb-artist-content-timetable-booth-1').style.display = 'block'
        document.getElementById('vb-artist-content-timetable-booth-2').style.display = 'block'
    }
}

function toggleTimetable(boothNumber)
{
    if(boothNumber == 1)
    {
        currentTimetableState = TimetableState.BOOTH_1; 
        document.getElementById('vb-artist-content-timetable-booth-1').style.display = 'block'
        document.getElementById('vb-artist-content-timetable-booth-2').style.display = 'none'
    }
    else if(boothNumber == 2)
    {
        currentTimetableState = TimetableState.BOOTH_2;
        document.getElementById('vb-artist-content-timetable-booth-1').style.display = 'none'
        document.getElementById('vb-artist-content-timetable-booth-2').style.display = 'block'
    }
}

function get(name){
    if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(location.search))
       return decodeURIComponent(name[1]);
 }

 function pushCurrentState()
 {
    // console.log(currentWebLocation)
    history.pushState({
        menu: currentWebLocation.menu, 
        page: currentWebLocation.page,
        artistState: currentWebLocation.artistState,
        artist: currentWebLocation.artist,
        work: currentWebLocation.work
    }, webTitle);
 }

window.addEventListener('popstate', e=>{
    //  console.log('pop')
    // console.log(e.state);

    let menuNumber = e.state.menu;
    let pageNumber = e.state.page;
    let artistStateNumber = e.state.artistState;
    let artistNumber = e.state.artist;
    let workNumber = e.state.work;

    if(menuNumber!== undefined && 0<=menuNumber && menuNumber<=2)
    {
        // console.log('Menu : ' + menuNumber);
        setMenubar(menuNumber, false);
        if(menuNumber == 2 && pageNumber!==undefined && 0<=pageNumber && pageNumber<=4)
        {
            // console.log('Page : ' + pageNumber);
            setVbMenubar(pageNumber, false);
            if(artistStateNumber == ArtistShowState.ARTIST_INFO)
            {
                if(artistNumber!==undefined && 0<= artistNumber && artistNumber < numberOfArtists)
                {
                    // console.log('Artist : ' + artistNumber);
                    openArtistInfo(artistNumber, true, false);
                }
            }
            else if(artistStateNumber == ArtistShowState.ARTIST_WORK)
            {
                if(artistNumber!==undefined && 0<= artistNumber && artistNumber < numberOfArtists)
                {
                    // console.log('Artist : ' + artistNumber);
                    openArtistInfo(artistNumber, true, false);

                    if(workNumber!==undefined && 0<=workNumber && workNumber < artistImages[artistNumber].length)
                    {
                        // console.log('Work : ' + workNumber);    
                        openArtistInfo_Work(workNumber);
                    }
                }
            }
            else if(artistStateNumber == ArtistShowState.ARTIST_TIMETABLE)
            {
                if(artistNumber!==undefined && 0<= artistNumber && artistNumber < numberOfArtists)
                {
                    // console.log('Artist : ' + artistNumber);
                    openArtistInfo(artistNumber, true, false);

                    openTimetable();
                }
            }
            else
            {
                window.scrollTo(0, 0);
            }
            
        }
        else
            {
                window.scrollTo(0, 0);
            }
    }
 })

 function changeIframeSrc(iframe, src) {
    var frame = iframe.cloneNode();
    frame.src = src;
    iframe.parentNode.replaceChild(frame, iframe);
};


// CODE FOR DISABLING SCROLLING

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; } 
  }));
} catch(e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt); 
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

// CODE FOR DISABLING SCROLLING END

// function setMetaImage(elem, url_input, defaultImg) {
//     var txt = "";
//     let url_out = '';
//     // sample url used here, you can make it more dynamic as per your need.
//     // used AJAX here to just hit the url & get the page source from those website. It's used here like the way CURL or file_get_contents (https://www.php.net/manual/en/function.file-get-contents.php) from PHP used to get the page source.
//     $.ajax({
//         type: 'GET',
//         url: url_input,
//         dataType: "jsonp",
//               error: function() {
//                   txt = "Unable to retrieve webpage source HTML";
//               }, 
//         success: function(response){
//             // will get the output here in string format
//             // used $.parseHTML to get DOM elements from the retrieved HTML string. Reference: https://api.jquery.com/jquery.parsehtml/
//             response = $.parseHTML(response);
//             console.log(response)
//             $.each(response, function(i, el){
//                 if(el.nodeName.toString().toLowerCase() == 'meta' && $(el).attr("name") != null && typeof $(el).attr("name") != "undefined")
//                 {
//                     if($(el).attr("name") == 'image' || $(el).attr("name") == 'twitter:image' || $(el).attr("name") == 'og:image')
//                     {
//                         url_out =  ($(el).attr("content")?$(el).attr("content"):($(el).attr("value")?$(el).attr("value"):"")).toString();
//                     }
//                 }
//                 else if(el.nodeName.toString().toLowerCase() == 'meta' && $(el).attr("property") != null && typeof $(el).attr("property") != "undefined")
//                 {
//                     if($(el).attr("property") == 'image' || $(el).attr("property") == 'twitter:image' || $(el).attr("property") == 'og:image')
//                     {
//                         url_out =  ($(el).attr("content")?$(el).attr("content"):($(el).attr("value")?$(el).attr("value"):"")).toString();
//                     }
//                 }
//             });

//         },
//         complete: function(){
//             if(url_out)
//             {
//                 elem.setAttribute('src', url_out);
//                 // console.log(url_out)
//             }
//             else
//             {
//                 elem.setAttribute('src', defaultImg);
//             }
//         }
//     });
// }
